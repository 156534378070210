.header {
    padding: 40px 20px 50px 20px;
}
.header-title {
    color: var(--text-dark);
    font-weight: 700;
    font-size: 41px;
}

@media (max-width: 768px) {
    .header {
        padding: 20px 16px;
    }
}