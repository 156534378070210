:root {
    --color-main: #ffffff;
    --color-secondary: #f6f7f8;
    --color-tertiary: #fafafa;
    --color-primary: #FF0078;
    --color-border: #dee3e8;
    --color-spot: #1ED760;

    --text-light: #ffffff;
    --text-dark: #2E2E3B;
    --box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
    --box-shadow-hover: 0 12px 22px rgb(0 0 0 / 32%);
    
    --transition-duration: .3s;

    --border-radius: 20px;
    --border-radius-sm: 4px;

    --color-1: #e17076;
    --color-2: #7bc862;
    --color-3: #65aadd;
    --color-4: #a695e7;
    --color-5: #ee7aae;
    --color-6: #6ec9cb;
    --color-7: #faa774;
    --nav-width: 235px;
    --w-max-xl: 1280px;
}

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
}
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: var(--color-tertiary);
}
*::-webkit-scrollbar-thumb {
    min-height: 65px;
    background-color: var(--color-border);
    border: 1px solid var(--color-border);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
html {
    overflow-x: hidden;
}

.content {
    max-width: var(--w-max-xl);
    width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 1rem;
}
.content-body {
flex-grow: 1;
}

body {
    font-family: Rubik,sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-size: 16px;
    /* background-color: var(--color-tertiary); */
    color: var(--text-dark);
    overflow-x: hidden;
    min-height: 100vh;
    letter-spacing: 0.3px;
}

.container {
    margin: 0 auto;
    padding: 0 16px;
}

.app {
    min-height: 100%;
    position: relative
}

.btn {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 600;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    min-width: 64px;
    padding: 6px 16px;
    font-size: 0.875rem;
    border-radius: 50px;
    transition: var(--transition-duration);
    border: 1px solid var(--text-dark);
    background-color: var(--text-dark);
    color: var(--text-light);
    fill: var(--text-light);
    transition: 0.3s;
    box-shadow: var(--box-shadow);
}
.btn:hover {
    opacity: 0.8;
    box-shadow: var(--box-shadow-hover);
}
.btn.active {
    opacity: 1;
}
.btn.btn-spot {
    color: black;
    fill: black;
    border: 1px solid var(--color-spot);
    background-color: var(--color-spot);
}
.btn.btn-primary {
    color: black;
    fill: black;
    border: 1px solid var(--color-primary);
    background-color: var(--color-primary);
}
.btn svg {
    font-size: 20px;
    width: 1em;
    height: 1em;
    margin-right: 8px;
}
.btn.btn-lg {
    padding: 0.8em 2.5em .98em;
    font-weight: 700;
    font-size: 1.4em;
}
.btn.btn-lg svg {
    width: 1.5em;
    height: 1.5em;
}

.grid-col-1-1 {
    grid-column: 1/-1;
}
.flex-row {
    display: flex;
}
.flex-wrap {
    flex-wrap: wrap;
}
.grid-sm .img-cover {
    margin: 0;
}
.flex {
    display: flex;
}
.justify-between {
    justify-content: space-between;
}
.justify-center {
    justify-content: center;
}
.align-center {
    align-items: center;
}
.flex-col {
    flex-direction: column;
}

.border-bottom {
    border-bottom: 1px solid var(--color-tertiary);
}

.border-top {
    border-top: 1px solid var(--color-tertiary);
}
.border-r {
    border-radius: var(--border-radius-sm);
}

.relative {
    position: relative;
}
.overflow-hidden {
    overflow: hidden;
}

.text-center {
    text-align: center;
}
.text-end {
    text-align: right;
}

.absolute {
    display: absolute;
}

.shadow {
    box-shadow: var(--box-shadow);
}

.filter-shadow {
    filter: drop-shadow(var(--box-shadow));
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.min-h-sm {
    min-height: 95px;
}

.h-screen {
    height: 100dvh;
}
.h-100 {
    height: 100%;
}
.w-100 {
    width: 100%;
}

.flex-grow-0 {
    flex-grow: 0;
}
.flex-grow {
    flex-grow: 1;
}
.gap-1 {
    gap: 12px;
}

.title-1 {
    font-size: 3.5em;
    font-weight: 700;
    line-height: 1.2;
}
.title-2 {
    font-size: 1.6em;
    font-weight: 400;
    line-height: 1.2;
}
.title-3 {
    font-size: 1.3em;
    font-weight: 400;
    line-height: 1.2;
}
.fs-4 {
    font-size: 16px;
    font-weight: 500;
}
.fs-5 {
    font-size: 14px;
    font-weight: 400;
}
.fs-6 {
    font-size: 12px;
    font-weight: 400;
}

.text-secondary {
    opacity: 0.5;
}
.text-capitalize {
    text-transform: capitalize;
}

.mx-w-sm {
    max-width: 640px;
}

.m-0 {
    margin: 0!important;
}
.px-1 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-lg {
    padding-left: 2rem;
    padding-right: 2rem;
}
.p-1 {
    padding: 1rem;
}
.p-2 {
    padding: 0.75rem;
}
.p-3 {
    padding: 0.5rem;
}
.p-4 {
    padding: 0.25rem;
}
.pt-2 {
    padding-top: 1rem;
}
.py-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.px-2 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}
.px-3 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
.pt-1 {
    padding-top: 3rem;
}
.pr-1 {
    padding-right: 1rem;
}
.pr-2 {
    padding-right: .75rem;
}
.pr-3 {
    padding-right: 0.5rem;
}
.pr-5 {
    padding-right: 0.25rem;
}
.pl-1 {
    padding-left: 1rem;
}

.pb-1 {
    padding-bottom: 3rem;
}
.pb-2 {
    padding-bottom: 1rem;
}
.mt-1 {
    margin-top: 3rem;
}
.mt-2 {
    margin-top: 2rem;
}
.mt-3 {
    margin-top: 1rem;
}
.mt-4 {
    margin-top: 0.5rem;
}
.mt-5 {
    margin-top: 0.25rem;
}

.mx-w-fs {
    max-width: 22px;
    min-width: 22px;
    text-align: center;
    padding-right: 16px;
}

.bg-hover {
    transition-duration: var(--transition-duration);
}
.bg-hover:hover {
    background-color: var(--color-tertiary);
}
.bg-gradient {
    background: rgb(255,0,120);
    background: linear-gradient(0deg, rgba(255,0,120,1) 0%, rgba(255,0,168,1) 100%);
}
.bg-primary {
    background: var(--color-primary)
}
.bg-main {
    background-color: var(--color-main);
}
.bg-menu {
    background-color: var(--text-menu);
}
.text-menu {
    color: var(--text-menu);
}
.bold {
    font-weight: 500!important;
}
.weight-800 {
    font-weight: 800!important;
}

a {
    text-decoration: none;
    color: var(--text-dark);
    fill: var(--text-dark);
}

.opacity-0 {
    opacity: 0;
}

.img-cover {
    margin-right: 1em;
    background: #fafafa;
    transition: opacity .1s linear;
    float: left;
    min-width: 50px;
    width: 50px;
    flex: 50px 0;
    background-repeat: no-repeat;
}
.img-cover-l {
    height: 50px;
}
.img-cover.img-cover-grid {
    margin: 0;
}

.item-anim {
    -webkit-transform: translate3d(0,-4px,0);
    transform: translate3d(0,-4px,0);
    opacity: 0;
    -webkit-animation-name: top-drop;
    animation-name: top-drop;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: calc(var(--order) * 150ms);
}

.show-on-saving {
    display: none;
}
.saving .show-on-saving {
    display: block;
}

.image-node.saving .item-anim {
    -webkit-transform: translate3d(0,-0,0)!important;
    transform: translate3d(0,-0,0)!important;
    opacity: 1!important;
    -webkit-animation-name: none!important;
    animation-name: none!important;
    animation-duration: 0s!important;
    animation-delay: 0!important;
}

.spinner svg {
    animation: spin 1s linear infinite;
}

/* .spotify-logo img {
    width: 120px;
} */
.spotify-logo svg {
    width: 120px;
    min-width: 120px;
    fill: #000000!important;
}
.spotify-logo svg path {
    fill: #000000!important;
}

.icon-lg {
    width: 30px!important;
    height: 30px!important;
    fill: var(--text-dark);
}
.icon-lg svg {
    width: 30px;
    height: 30px;
    fill: var(--text-dark);
}
.icon-sm svg {
    width: 24px;
    height: 24px;
    fill: var(--text-dark);
}
.icon-btn {
    padding: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition-duration: var(--transition-duration);
}

.pointer {
    cursor: pointer;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes top-drop {
    0% {
        -webkit-transform: translate3d(0,-4px,0);
        transform: translate3d(0,-4px,0);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

.border {
    border: 1px solid var(--color-border);
}
.border-radius {
    border-radius: 20px;
}

.fs-20 {
    font-size: 20px;
}

.bold {
    font-weight: bold;
}

.fs-16 {
    font-size: 16px;
}

.fs-12 {
    font-size: 12px;
}

.fs-10 {
    font-size: 10px;
}
.content-body {
    padding-bottom: 52px;
}

@media (max-width: 769px) {
    .btn.btn-lg {
        padding: 0.8em 2.4em .78em;
        font-weight: 700;
        font-size: 1em;
    }

    .title-1 {
        font-size: 3em;
        font-weight: 700;
        line-height: 1.2;
    }
    .title-2 {
        font-size: 1.3em;
        font-weight: 400;
        line-height: 1.2;
    }
    .title-3 {
        font-size: 1em;
        font-weight: 400;
    }
    .fs-4 {
        font-size: 15px;
    }
    .fs-5 {
        font-size: 13px;
    }
    .fs-6 {
        font-size: 11px;
    }

    .border-sm-none {
        border: none!important;
    }
    .border-radius-sm-none {
        border-radius: 0!important;
    }

    .content {
        flex-direction: column;
        gap: 0;
    }

    .img-cover {
        margin-right: 0.5em;
        width: 45px;
        min-width: 45px;
    }
    .img-cover-l {
        height: 50px;
    }
    .spotify-logo svg {
        width: 80px;
        min-width: 80px;
    }
    
    .d-none-sm {
        display: none;
    }

    .p-sm-3 {
        padding: 0.5rem;
    }
    *::-webkit-scrollbar {
        display: none;
    }

    .item-anim {
        padding-right: 0.5rem;
    }
    .container {
        padding: 0;
    }
}
.animation-slide-in {
    opacity: 0;
    -webkit-animation-name: slide-in;
    animation-name: slide-in;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-delay: calc(var(--slide-in-delay, 0) * 0.05);
    animation-delay: calc(var(--slide-in-delay, 0) * 0.05);
    -webkit-animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards; 
}
@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    50% {
        /* opacity: 0.75; */
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 330px) {
    .flex-min-wrap {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 8px;
    }
}