.dig {
    background-image: url("../../../assets/img/dig.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px 20px;
    height: 640px;
}

.dig span {
    user-select: none;
}

.dig-items {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: 14px;
    color: #fff;
    gap: 5px;
}

.dig-genre {
    width: 40%;
    max-width: 40%;
    min-width: 40%;
}

.dig-artists {
    width: 30%;
    max-width: 30%;
    min-width: 30%;
}

@media (max-width: 769px) {
    .dig-items {
        font-size: 10px;
    }
}