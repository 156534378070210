.sidebar-wrapper {
    width: var(--nav-width);
    max-width: var(--nav-width);
    overflow-x: hidden;
    min-width: var(--nav-width);
    background-color: var(--color-main);
}
.sidebar {
    padding: 0 12px;
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: inherit;
    overflow-x: hidden;
    scroll-behavior:unset
}

.sidebar .logo {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding:14px 28px;
}

.sidebar .logo svg {
    width: 50px;
    height: 50px;
}

.sidebar .sidebar-item {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--text-menu);
    fill: var(--text-menu);
    font-size: 15px;
    transition-duration: var(--transition-duration);
    cursor: pointer;
    padding: 14px 28px;
    font-weight: 500;
    border-left: 3px solid transparent;
}

.sidebar-item.active,
.sidebar-item:hover {
    border-color: var(--text-menu)!important;
}
.sidebar .sidebar-item.active {
    opacity: 1;
    color: var(--text-menu);
    fill: var(--text-menu);
}

.sidebar .sidebar-item svg {
    width: 24px;
    height: 24px;
}

.sidebar .footer {
    padding: 1rem 8px 0 8px;
}

.sidebar .footer a {
    font-size: 10px;
    padding-top: 2px;
    padding-bottom: 8px;
    transition-duration: var(--transition-duration);
}

.sidebar .footer a:hover {
    color: var(--color-primary);
}

.sidebar-top {
    display: flex;
    flex-direction: column;
}

@media (max-width: 769px) {
    .sidebar-top {
        flex-direction: row;
    }
    .sidebar-item.active,
    .sidebar-item:hover {
        border-radius: 20px;
        scale: 1.1;
    }
    .sidebar {
        padding: 8px 12px;
        left: auto;
        top: auto;
        right: auto;
        justify-content: space-around;
        bottom: 0;
        flex-direction: row;
        height: auto;
        width: 100vw;
        width: -webkit-fill-available;
        background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(15px);
        z-index: 999;
    }

    .sidebar .footer,
    .sidebar .logo {
        display: none;
    }

    .sidebar-top {
        display: flex;
        flex-grow: 1;
        justify-content: space-evenly;
    }

    .sidebar .sidebar-item {
        flex: 1;
        padding: 0;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border:none;
        justify-content: center;
        gap: 0px;
    }
    .sidebar .sidebar-item span {
        display: none;
    }

    .sidebar .sidebar-item svg {
        width: 20px;
        height: 20px;
        padding: 8px 0;
    }
}